<template>
    <div class="page-table musteriTanim  mainDiv pt-7" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            Cari Tanımla
        </div>
        
        <el-row :gutter="10" v-loading="loading"
            element-loading-text='Cari Tanımlanıyor. Lütfen Bekleyiniz.'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="kurumForm" :rules="rulesKurum" ref="kurumForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">                        
                        <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10" >
                            <el-row>
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Cari Adı" prop="kurumAdi">:
                                        <el-input size="medium"
                                            v-model="kurumForm.kurumAdi"
                                            :placeholder="'Cari Adı Giriniz'"></el-input>
                                    </el-form-item>
                                </el-col>                             
                              
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Cari Durumu" prop="kurumDurumu">:
                                        <br>
                                        <!--
                                            <el-checkbox size="medium" label="1" v-model="kurumForm.gelen" border>Gelen</el-checkbox>
                                            <el-checkbox size="medium" label="2" v-model="kurumForm.giden" border>Giden</el-checkbox>
                                            -->

                                            <div style="width: 200px;">
                                                <el-switch
                                                    v-model="kurumForm.kurumDurumu"
                                                    active-color="#048484"
                                                    inactive-color="#048484"
                                                    active-text="Giden"
                                                    inactive-text="Gelen"
                                                    :active-value="2"
                                                    :inactive-value="1">
                                                </el-switch>
                                            </div>


                                    </el-form-item>
                                </el-col> 
                            </el-row>                                                    
                        </div>                   

                </el-col>

                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="8" :title='$t("src.views.apps.genel.durum")'>
                            <el-row> 
                                <!--
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="durum">
                                        <el-select
                                            size="small"
                                            v-model="kurumForm.durum"
                                            :placeholder='$t("src.views.apps.messages.addBultenTitle")'
                                            style="width: 100%">
                                            <el-option value="0" :label='$t("src.views.apps.durum.kurum.pasif")'></el-option>
                                            <el-option value="1" :label='$t("src.views.apps.durum.kurum.aktif")'></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>-->
                                <el-button size="mini" v-on:click="kurumEkle('kurumForm',kurumForm.durum)" class="onayBtn" type="primary">
                                    <label>Kaydet</label>
                                </el-button>
                                &nbsp;
                                <el-popover placement="bottom" width="310" v-model="visibleSil">
                                    <p>{{$t('src.views.apps.messages.clearFormAlert')}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="primary" @click="resetForm('kurumForm')">
                                            {{$t('src.views.apps.genel.yes')}}
                                        </el-button>
                                        <el-button size="mini" @click="visibleSil = false;">
                                            {{$t('src.views.apps.genel.no')}}
                                        </el-button>
                                    </div>
                                    <el-button size="mini" slot="reference">
                                        {{$t('src.views.apps.genel.temizle')}}
                                    </el-button>
                                </el-popover>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>
<script>
    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import draggable from "vuedraggable";

    import kurumService from '../../../WSProvider/KurumService'

    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import JQuery from 'jquery';

    let $ = JQuery;

    var moment = require('moment');

    export default {
        name: "CariTanimla",
        components: {
            draggable
        },
        
        mounted() {

        },
        
        data() {
            return {
                radio: ['1'],
                selectedOption: ['Gelen', 'Giden'],
                loading : false,
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr',
                },
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "11"],
                visibleSil: false,
                disabled: false,
                kurumForm: {
                    kurumAdi: '',
                    gelen: "",
                    giden: "",
                    kurumDurumu: '',         
                    durum: '1',
                },
                rulesKurum: {                    
                    kurumAdi: [{
                        required: true,
                        message: "Lütfen Kurum Adı Giriniz",
                        trigger: 'blur'
                    }],   /* 
                    kurumDurumu: [{
                        required: true,
                        message: "Lütfen Kurum Durumu Seçiniz",
                        trigger: 'blur'
                    }],     */            
                },
            }
        },
        methods: {

            kurumEkle(formName) {
                this.$refs[formName].validate((valid, errors) => {
                    if (valid) {
                        this.$confirm("Kurumu tanımlamak istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
                            confirmButtonText: this.$t("src.views.apps.genel.yes"),
                            cancelButtonText: this.$t("src.views.apps.genel.no"),
                            type: 'warning'
                        }).then(() => {
                            this.loading = true;

                            /*
                            if(this.kurumForm.gelen != "" && this.kurumForm.giden != ""){                                
                                    notification.Status("warning", this, "Kurum durumundan sadece birini seçiniz.")
                                    this.loading = false;
                                    return;
                            }

                            if(this.kurumForm.gelen != "" && this.kurumForm.giden == "")
                            {
                                this.kurumForm.kurumDurumu = 1
                            }

                            if(this.kurumForm.gelen == "" && this.kurumForm.giden != "")
                            {
                                this.kurumForm.kurumDurumu = 2
                            }*/

                            kurumService.kurumEkle(this.kurumForm.kurumAdi, this.kurumForm.kurumDurumu, 1).then((response) => {
                                                               

                                if(response.status == 200){
                                    localStorage.setItem("userDataBGSurec", response.token)    
                                    notification.Status("success", this, response.msg)                                
                                    EventBus.$emit("cariList", true)
                                    functions.sayfaKapat("caritanimla", this);
                                    this.resetForm(formName)
                                }
                                this.loading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.loading = false;
                            })
                        }).catch((e) => {})
                    }else{
                        for(var k in errors){
                            errors[k].forEach((element) => {
                                notification.Status( "warning", this, element.message);
                            })  
                            this.loading = false
                        }
                    }
                })
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.visibleSil = false;
            },

        }
    }

</script>

<style>
.el-loading-spinner {
    top: 1% !important;
}
</style>